import React, {useEffect, useState} from 'react';

const MONTH_NAMES = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
const WEEKDAY_NAMES = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

const ScreenBar = () => {
  const [time, setTime] = useState<string>("");
  const [date, setDate] = useState<string>("")

  useEffect(() => {
    let update = () => {
      const d = new Date();

      const hh = d.getHours().toString().padStart(2, "0");
      const mm = d.getMinutes().toString().padStart(2, "0");

      const yyyy = d.getFullYear().toString();
      const monthname = MONTH_NAMES[d.getMonth()];
      const dd = d.getDate()
      const weekday = WEEKDAY_NAMES[d.getDay()];

      setTime(`${hh}:${mm}`);
      setDate(`${weekday}, ${dd}. ${monthname} ${yyyy}`);
    }

    setInterval(update, 1000);
    update();
  }, [])

  return (
    <div className={"flex flex-row justify-between px-8 py-6 bg-zinc-950 bg-opacity-70"}>
      <p>{date}</p>
      <p>{time}</p>
    </div>
  );
};

export default ScreenBar;
