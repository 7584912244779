import React from 'react';
import PanelWrapper from "../../meta/PanelWrapper";
import PanelContent from "../../meta/PanelContent";
import {Warning} from "@phosphor-icons/react";

export type CalloutPanelDefinition = {
  type?: "warning",
  title?: string,
  description?: string
}

const CalloutPanel = (props: {definition: CalloutPanelDefinition}) => {
  return (
    <PanelWrapper>
      <div className={"flex flex-row h-full w-full"}>
        <div
          className={"bg-yellow-500 aspect-square flex justify-center items-center"}
        >
          <Warning size={48} className={"text-zinc-900"} />
        </div>

        <PanelContent className={"flex-1 flex justify-start items-center"} padding={false}>
          <div className={"px-6 py-5"}>
            {props.definition.title && (
              <h2 className={"text-lg font-semibold"}>
                {props.definition.title}
              </h2>
            )}

            {props.definition.description && (
              <p className={"mt-1 text-sm"}>
                {props.definition.description}
              </p>
            )}
          </div>
        </PanelContent>
      </div>
    </PanelWrapper>
  );
};

export default CalloutPanel;
