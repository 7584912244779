import {useEffect, useRef, useState} from "react";
import {LayoutConfig} from "../types/LayoutConfig";
import {LayoutService} from "../services/LayoutService";

export default function useLayout(): LayoutConfig | null {
  const currentId = useRef<string | null>(null);
  const [layout, setLayout] = useState<LayoutConfig | null>(null);

  useEffect(() => {
    LayoutService.init().then(() => {
      const refresh = () => {
        const activeLayout = LayoutService.getActiveLayout();

        if(currentId.current !== activeLayout.id) {
          console.log("Switching from", currentId.current, "to", activeLayout.id)
          currentId.current = activeLayout.id;
          setLayout(activeLayout);
        }
      };

      window.setInterval(refresh, 10000);
      refresh();
    });
  }, []);

  return layout;
}
