import React from 'react';
import PanelWrapper from "../../meta/PanelWrapper";

const ErrorPanel = (props: {message?: string}) => {
  const message = props.message ?? "Failed to render Panel";

  return (
    <PanelWrapper className={"bg-red-500 px-4 py-3 flex items-center justify-center"}>
      {message}
    </PanelWrapper>
  );
};

export default ErrorPanel;
