import { useEffect, useState } from "react";
import PanelWrapper from "../../meta/PanelWrapper";
import PanelTitle from "../../meta/PanelTitle";
import PanelContent from "../../meta/PanelContent";
import StationElement from "./components/StationElement";

type NextbikeAPIResponse = {
  countries: {
    cities: {
      places: {
        number: number,
        bikes_available_to_rent: number
      }[]
    }[]
  }[]
}

type NextbikePanelDefinition = {
  city: string;
  station_ids: number[];
  station_names: string[];
}

type NextbikeStation = {
  id: number,
  name: string,
  available: number
}

const NextbikePanel = (props: {definition: NextbikePanelDefinition}) => {
  const [stations, setStations] = useState<NextbikeStation[]>([]);

  useEffect(() => {
    const update = async () => {
      try {
        const request = await fetch(`https://api.nextbike.net/maps/nextbike-live.json?city=${props.definition.city}`);

        if (request.status !== 200) {
          return;
        }

        const data = await request.json() as NextbikeAPIResponse;
        
        const all_stations = data.countries[0].cities[0].places;
        const stations = all_stations.filter((station: any) => {
          return props.definition.station_ids.includes(station.number);
        });

        // sort as in definition
        stations.sort((a: any, b: any) => {
          return props.definition.station_ids.indexOf(a.number) - props.definition.station_ids.indexOf(b.number);
        });

        const available_bikes = stations.map((station: any) => {
          return {
            id: station.number,
            name: props.definition.station_names[props.definition.station_ids.indexOf(station.number)],
            available: station.bikes_available_to_rent
          }
        });
          
        setStations(available_bikes);
      }
      catch (e) {
        console.warn("NextbikePanel not showing data because", e);
      }
      
    }

    update();
    const interval = setInterval(update, 2 * 60 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, [props]);

  return (
  <PanelWrapper>
    <PanelTitle title={"Nextbike Monitor"}/>
    <PanelContent className={"flex flex-col"}>
      {stations.length > 0 ? (
        <div className={"flex-1 flex flex-col gap-3"}>
          {stations.map((station) => (
            <StationElement
              key={station.id}
              stationId={station.id}
              stationName={station.name}
              availableBikes={station.available}
            />
          ))}
        </div>
      ) : (
        <div className={"flex-1 flex justify-center items-center"}>
          <div className={"mb-10 flex flex-col items-center"}>
            <p className={"max-w-xs text-center text-zinc-400"}>
              Aktuell sind keine Daten verfügbar.
            </p>
          </div>
        </div>
      )}
    </PanelContent>
  </PanelWrapper>
  )
}

export default NextbikePanel;
