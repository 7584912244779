import React from 'react';
import classNames from "../util/classNames";

const PanelContent = (props: {padding?: boolean, className?: string, children: any}) => {
  return (
    <div className={classNames(
      "flex-1",
      (props.padding ?? true) ? "px-6 pb-5" : "",
      props.className
    )}>
      {props.children}
    </div>
  );
};

export default PanelContent;
