import React from 'react';
import ScreenBar from "./ScreenBar";

const ScreenWrapper = (props: {children: any, backgroundImage?: string}) => {
  return (
    <div className={"relative"}>
      <div
        className={"absolute inset-0 h-screen w-screen bg-cover blur-lg backdrop-brightness-75"}
        style={{backgroundImage: `url('${props.backgroundImage}')`}}
      >
      </div>

      <div className={"absolute inset-0 h-screen w-screen flex flex-col"}>
        <ScreenBar />
        <div
          className={"grid layout-grid flex-1 px-8 pb-8 pt-4 bg-contain bg-no-repeat bg-center"}
          style={{
            backgroundImage: `url('${props.backgroundImage}')`
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ScreenWrapper;
