import React from 'react';
import './App.css';
import ScreenWrapper from "./meta/ScreenWrapper";
import useLayout from "./hooks/useLayout";
import {PanelRenderers} from "./panels/_Panels";
import LayoutElement from "./layout/LayoutElement";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPanel from "./panels/Error/ErrorPanel";

function App() {
  const layout = useLayout();

  if(!layout) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div className={"overflow-hidden w-screen h-screen bg-zinc-950 text-white text-def"} style={{fontFamily: "Inter"}}>
      <ScreenWrapper backgroundImage={layout.background}>
        {
          layout.panels.map(panel => {
            try {
              const Renderer = PanelRenderers[panel.type];

              if(!Renderer) {
                console.error(`Rendering a panel of type ${panel.type} at (${panel.position.x}, ${panel.position.y}) failed. Config:`, panel.config)
                return (
                  <LayoutElement config={panel}>
                    <ErrorPanel
                      message={`Unable to find a panel definition for type "${panel.type}"`}
                    />
                  </LayoutElement>
                )
              }

              return (
                <LayoutElement config={panel}>
                  <ErrorBoundary fallback={
                    <ErrorPanel
                      message={`Panel of type ${panel.type} failed to render. Please check the config you provided.`}
                    />
                  }>
                    <Renderer definition={panel.config} />
                  </ErrorBoundary>
                </LayoutElement>
              )
            } catch (e) {
              return (
                <ErrorPanel/>
              );
            }
          })
        }
      </ScreenWrapper>
    </div>
  );
}

export default App;
