const ProgressBar = (props: { duration: number, idName: string, callbackFunction: () => void }) => {

  const runCallback = () => {
    props.callbackFunction();
    const el = document.getElementById(props.idName);
    if (el) {
      el.classList.remove('progress-bar-animate');
      void el.offsetWidth;
      el.classList.add('progress-bar-animate');
    }
  }

  return (
    <div className="progress-bar">
      <div 
        id={props.idName}
        className={'progress-bar-fill progress-bar-animate bg-zinc-700'}
        style={{ animationDuration: `${props.duration}ms` }} 
        onAnimationEnd={() => runCallback()}
      />
    </div>
  );
};

export default ProgressBar;
