import React from 'react';

const PanelWrapper = (props: {children: any, className?: string}) => {
  // ToDo: The className thing is not pretty. Re-do
  return (
    <div className={`w-full h-full bg-zinc-900 rounded-2xl overflow-hidden flex flex-col ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
};

export default PanelWrapper;
