import { useEffect, useState } from 'react';
import PanelWrapper from '../../meta/PanelWrapper';
import PanelTitle from '../../meta/PanelTitle';
import PanelContent from '../../meta/PanelContent';
import { Cloud, CloudFog, CloudLightning, CloudRain, CloudSnow, CloudSun, Icon, Sun } from '@phosphor-icons/react';
import { fetchWeatherApi } from 'openmeteo';

export type WetterPanelDefinition = {
    latitude: number,
    longitude: number,
}

const WetterPanel = (props: { definition: WetterPanelDefinition }) => {
    const [temperature, setTemperature] = useState<number>(0);
    const [weatherCode, setWeatherCode] = useState<number>(0);
    // TODO: how long will it rain

    useEffect(() => {
        // this function will be called every hour
        const update = async () => {
            // query open-meteo (https://github.com/open-meteo/typescript)
            const params = {
                latitude: [props.definition.latitude],
                longitude: [props.definition.longitude],
                current: 'temperature_2m,precipitation,weather_code,rain,showers',
                forecast_days: 1,
            }

            const url = 'https://api.open-meteo.com/v1/forecast';
            const currentWeather = (await fetchWeatherApi(url, params))[0].current()!;

            setTemperature(Math.round(currentWeather.variables(0)!.value()))
            setWeatherCode(currentWeather.variables(1)!.value())
        }

        // call it manually the first time
        update();
        const interval = setInterval(update, 1000 * 60 * 60);

        return () => {
            // clear up old handle in case this component is cleaned up
            clearInterval(interval)
        }
    });

    const renderWeather = (weatherCode: number, temperature: number) => {
        const [WeatherIcon, text] = wcToIconText(weatherCode)!;
        return (<div className='clex-1 flex flex-row gap-2 items-center'>
            <WeatherIcon size={32} />
            <p>{text} <span className='text-gray-400'>{temperature}°C</span></p>
        </div>);
    };

    return (
        <PanelWrapper className={"relative"}>
            <PanelTitle title={"Wetter"} />
            <PanelContent>
                <div className={"flex flex-row gap-4 items-center"}>
                    {renderWeather(weatherCode, temperature)}
                </div>
            </PanelContent>
        </PanelWrapper>
    );
};

/**
 * Take a weather code and give an icon and text for the weather
 * @param weather_code weather code (see https://open-meteo.com/en/docs)
 * @returns Tuple of Icon and text or undefined
 */
function wcToIconText(weather_code: number): [Icon, string] | undefined {
    switch (true) {
        case weather_code === 0: return [Sun, "Sonnig"]
        case weather_code <= 2: return [CloudSun, "Bewölkt"]
        case weather_code <= 3: return [Cloud, "Bedeckt"]
        case weather_code <= 48: return [CloudFog, "Nebel"]
        case weather_code <= 67: return [CloudRain, "Regen"]
        case weather_code <= 77: return [CloudSnow, "Schneefall"]
        case weather_code <= 82: return [CloudRain, "Starker Regen"]
        case weather_code <= 86: return [CloudSnow, "Starker Schneefall"]
        case weather_code <= 99: return [CloudLightning, "Gewitter"]
        default: return undefined
    }

}

export default WetterPanel;
