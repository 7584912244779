

const StationElement = (props: {
  stationName: string,
  stationId: number,
  availableBikes: number
  }) => {
  return (
    <div className={"flex flex-row gap-4 items-center"}>
      <div className={"text-lg font-semibold h-10 w-20 leading-none text-white flex justify-center items-center"}
        style={{ "background": "#ec7100" }}>
        {props.stationId}
      </div>
      <h3 className={"text font-semibold leading-9 flex-1"}>
        {props.stationName}
      </h3>
      <p className={"text-zinc-400"}>
        {props.availableBikes} verfügbar
      </p>
    </div>
  );
};

export default StationElement;