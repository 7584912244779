import React from 'react';
import {PanelDefinition} from "../types/LayoutConfig";

const LayoutElement = (props: {children: any, config: PanelDefinition<any>}) => {
  return (
    <div
       style={{
         gridColumnStart: props.config.position.x + 1,
         gridColumnEnd: props.config.position.x + props.config.position.w + 1,
         gridRowStart: props.config.position.y + 1,
         gridRowEnd: props.config.position.y + props.config.position.h + 1
       }}
    >
      {props.children}
    </div>
  );
};

export default LayoutElement;
