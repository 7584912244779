import { useEffect, useState } from "react";
import PanelWrapper from "../../meta/PanelWrapper";
import PanelTitle from "../../meta/PanelTitle";
import PanelContent from "../../meta/PanelContent";

type MensaJetztAPIResponse = {
  day: string,
  date: string,
  attendance: Attendee[],
}

type Attendee = {
  "name": string,
  "name_modifiers": string,
  "time": string,
  "canteen": string,
  "color": string,
}

type Attendance = { [time: string]: Attendee[] }

const MensaJetztPanel = () => {
  const [marqueeContent, setMarqueeContent] = useState<string>("Niemand :(");

  useEffect(() => {
    const update = async () => {
      try {
        const request = await fetch(`https://mensa.jetzt/api/entries/`);

        if (request.status !== 200) {
          setMarqueeContent("API Error :(");
          console.error("mensa.jetzt API returned error code");
          return;
        }

        const data = await request.json() as MensaJetztAPIResponse;
        let marquee = Object.entries(
          data.attendance
            .sort((a, b) => a.time.localeCompare(b.time))
            .reduce((acc, curr) => {
              if (acc[curr.time]) {
                acc[curr.time].push(curr);
              } else {
                acc[curr.time] = [curr];
              }
              return acc;
            }, {} as Attendance)
        ).map(([time, attendees]) => `${time} - ${attendees.map(a => a.name).join(", ")}`)
        .join(" | ");

        if (marquee) {
          marquee += " |";
        } else {
          marquee = "Niemand :(";
        }
        
        setMarqueeContent(marquee.trim());
      }
      catch (e) {
        console.warn("mensa.jetzt not showing data because", e);
        setMarqueeContent("Niemand :(");
      }
    }

    update();
    const interval = setInterval(update, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <PanelWrapper className={"relative"}>
      <PanelTitle title={"Mensa.jetzt"}/>
      <PanelContent>
        {marqueeContent.includes("Uhr") ? (
          <div className={"flex overflow-hidden whitespace-nowrap"}>
            <div className={"animate-marquee"}>
              {marqueeContent}
            </div>
            <div className={"animate-marquee ml-1"}>
              {marqueeContent}
            </div>
          </div>
        ) : (
          <div className={"text-center"}>{marqueeContent}</div>
        )}
      </PanelContent>
    </PanelWrapper>
  );
};

export default MensaJetztPanel;
