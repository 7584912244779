import classNames from "../../../util/classNames";

type ProgressIndicatorProps = {
  first?: boolean,
  id: string,
  name: string,
  arrival: Date,
  delay?: number,
  cancelled: boolean
}

export default function ProgressIndicator(props: Readonly<ProgressIndicatorProps>) {
  return (
      <li className={`relative ${!(props.first ?? false) ? "-mt-3.5" : "-mt-2"} text-sm text-zinc-300`} key={props.id}>
        <div className={"flex flex-row gap-4"}>
          <div className={"w-20 flex flex-row justify-center"}>
            <div className="w-[.25rem] h-6 -mb-5 bg-zinc-400" aria-hidden="true" />
          </div>
          <div className={"flex-1"}></div>
        </div>

        <div className={classNames("relative flex flex-row items-center gap-4", props.cancelled ? "text-red-400" : "")}
        style={{
          textDecoration: props.cancelled ? "line-through" : "inherit"
        }}>
          <div className={"w-20 flex flex-row justify-center"}>
            <span className="h-[2.6rem] flex items-center" aria-hidden="true">
              <span className="relative z-10 w-[1.5rem] h-[1.5rem] flex items-center justify-center bg-zinc-400 rounded-full">
                <span className="h-[1rem] w-[1rem] bg-zinc-900 rounded-full" />
              </span>
            </span>
          </div>

          <div className={"flex-1"}>
            {props.name}
          </div>

          <div className={classNames("tabular-nums", (props.delay || props.cancelled) ? "text-red-400" : "text-zinc-300")}>
            {props.delay ? (
              <span className={"text-red-400"}>
                (+{props.delay})&nbsp;&nbsp;
              </span>
            ) : null}
            {renderTime(props.arrival)}
          </div>
        </div>
      </li>
  )
}

const renderTime = (date: Date) => {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`
}
