import React from 'react';
import PanelWrapper from "../../meta/PanelWrapper";
import classNames from "../../util/classNames";
import PanelContent from "../../meta/PanelContent";

export type BildPanelDefinition = {
  url: string,
  fit?: "fill" | "fit",
  title?: string,
  description?: string,
  gradient?: boolean
}

const BildPanel = (props: {definition: BildPanelDefinition}) => {
  return (
    <PanelWrapper className={"relative"}>
      <div
        className={"absolute inset-0 h-full w-full bg-cover blur-lg backdrop-brightness-75"}
        style={{backgroundImage: `url('${props.definition.url}')`}}
      >
      </div>

      <div className={"absolute inset-0 h-full w-full flex"}>
        <div
          className={classNames(
            "bg-no-repeat bg-center flex-1 relative",
            (props.definition.fit === "fit") ? "bg-contain" : "bg-cover"
          )}
          style={{
            backgroundImage: `url('${props.definition.url}')`
          }}
        >
          {props.definition.title && (
            <>
              <div className={classNames(
                "absolute inset-0 w-full h-full",
                (props.definition.gradient ?? true) ? "bg-lower-gradient" : ""
              )}>
              </div>

              <PanelContent className={"absolute inset-0 w-full h-full flex flex-col justify-end"}>
                <h2 className={"text-xl font-semibold"}>
                  {props.definition.title}
                </h2>

                {props.definition.description && (
                  <p className={"mt-1 text-sm max-w-5xl"}>
                    {props.definition.description}
                  </p>
                )}
              </PanelContent>
            </>
          )}
        </div>
      </div>
    </PanelWrapper>
  );
};

export default BildPanel;
