import React from 'react';

const PanelTitle = (props: {title: string, info?: string}) => {
  return (
    <div className={"px-6 py-4 text-zinc-400 flex flex-row justify-between"}>
      <h2>{props.title}</h2>
      {props.info && (
        <p>{props.info}</p>
      )}
    </div>
  );
};

export default PanelTitle;
